<template>
  <LoadingState v-if="isLoadingPage" />
  <div v-else>
    <div v-if="!menus.length">No Students Found</div>
    <div v-else>
      <div class="d-flex align-items-center"></div>
      <div class="mt-4">
        <a-row :gutter="[16, 16]">
          <a-col
            :sm="24"
            :xl="8"
            v-sticky="{
              zIndex: 99,
              stickyTop: 10,
              disabled: isDisabledSticky,
            }"
          >
            <div class="border rounded">
              <div class="card-header">
                <div
                  class="
                    d-flex
                    justify-content-between
                    align-items-center
                    w-100
                  "
                >
                  <div>
                    <h2 class="font-size-18 text-dark">List of Students</h2>
                  </div>
                </div>
              </div>
              <div class="card-body scrolling-card">
                <Menu
                  :menus="menus"
                  :menuActive="menuActive"
                  @menu-change="menuChange"
                />
              </div>
              <div class="card-footer">
                <kbd class="bg-primary">↑</kbd>
                <kbd class="bg-primary mx-2">↓</kbd>
                <span>To Navigate</span>
              </div>
            </div>
          </a-col>
          <a-col :sm="24" :xl="16">
            <div class="border rounded">
              <div class="card-header">
                <div
                  class="
                    d-flex
                    flex-column flex-md-row
                    align-items-start align-items-md-center
                    w-100
                  "
                >
                  <div>
                    <h2 class="font-size-18 text-dark">Preview</h2>
                  </div>
                  <div class="ml-0 ml-md-auto">
                    <div v-if="arrayGraduationLetter.length" class="text-center">
                      <a-button :loading="isExportedAll" @click.prevent="donwloadAllFile()" type="primary" icon="download">Download All Graduation Letter</a-button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="min-height: 562px"
                class="card-body d-flex flex-column align-items-center"
              >
                <LoadingState v-if="isLoadingPeriodicReportPreview" />
                <div v-else class="w-100">
                  <template v-if="dataGraduationLetter.uuid">
                    <graduation-letter :configSchool="config.school" :dataGraduationLetter="dataGraduationLetter || {}" />
                    <!-- <template v-if="dataGraduationLetter.version === null">
                      <report-mid-view v-if="type === 'mid'" :configSchool="config.school" :generalReport="dataGraduationLetter" />
                      <report-final-view v-else-if="type === 'final'" :configSchool="config.school" :generalReport="dataGraduationLetter" page />
                    </template>
                    <template v-if="dataGraduationLetter.version === 1">
                      <report-mid-view-v2 v-if="type === 'mid'" :configSchool="config.school" :dataGraduationLetter="dataGraduationLetter" />
                      <report-final-view-v2 v-else-if="type === 'final'" :configSchool="config.school" :dataGraduationLetter="dataGraduationLetter" page />
                    </template> -->
                  </template>
                  <EmptyState
                    class="my-5"
                    v-else
                    heading="No Data"
                    description="This student still has no Graduation Letter."
                  />
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <template v-if="false">
      <div class="d-none">
        <div id="reportHtml" class="w-100">
          <graduation-letter
            :format="
              isExported
                ? { width: '650px', height: '1037px' }
                : null
            "
            :configSchool="config.school"
            :dataGraduationLetter="dataGraduationLetter"
          />
        </div>
      </div>
    </template>
    <template v-if="arrayGraduationLetter.length">
      <div class="d-none">
        <div id="allGraduationLetterHtml" class="w-100">
          <div v-for="(letter, i) in arrayGraduationLetter" :key="'graduation-letter-' + i">
            <graduation-letter
              :format="
                isExportedAll
                  ? { width: '650px', height: '1037.5px' }
                  : null
              "
              :configSchool="config.school"
              :dataGraduationLetter="letter"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import VueSticky from 'vue-sticky'
import JsPdf from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import config from '@/config'
const LoadingState = () => import('@/components/app/LoadingState')
const Menu = () => import('@/components/app/MenuV2')
const GraduationLetterComponent = () => import('@/components/app/GraduationLetter')
const EmptyState = () => import('@/components/app/EmptyState')

const columns = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'No',
    width: 50,
    align: 'center',
  },
  {
    title: 'Subject',
    dataIndex: 'subject',
    key: 'subject',
    align: 'left',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    align: 'left',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    align: 'center',
  },
  {
    title: 'Score',
    dataIndex: 'score',
    key: 'score',
    width: 100,
    align: 'center',
  },
]

export default {
  props: {
    dataClass: {
      type: Object,
      required: true,
    },
  },
  directives: {
    sticky: VueSticky,
  },
  components: {
    LoadingState,
    Menu,
    GraduationLetter: GraduationLetterComponent,
    EmptyState,
  },
  data() {
    return {
      menus: [],
      columns,
      assignmentAndDailyExam: null,
      menuActive: 1,
      reportType: 'status',
      reportData: {},
      itemLoops: ['list_assignments', 'list_daily_exams'],
      selectedDates: [moment().add(-7, 'day'), moment()],
      formSendConfirmation: {
        selectedDates: null,
        reportType: 'status',
      },
      isLoadingPage: false,
      isLoadingPeriodicReportPreview: false,
      isVisibleModalSendConfirmation: false,
      isConfirmLoadingModalSendConfirmation: false,
      schoolYearActive: {},
      arrayGraduationLetter: [],
      isExported: false,
      isExportedAll: false,
      loadingListPersonalData: false,
      loadingDetailPersonalData: false,
      loadingExportAll: false,
      config,
    }
  },
  watch: {
  },
  async mounted() {
    try {
      this.fetchTahunAjaranActive()
      this.fetchDataListMurid()
      window.addEventListener('keyup', (event) => {
        event.preventDefault()
        if (this.menus.length) {
          if (event.keyCode === 40 && this.menuActive !== this.menus.length) {
            this.menuActive = this.menuActive + 1
          } else if (event.keyCode === 38 && this.menuActive !== 1) {
            this.menuActive = this.menuActive - 1
          }
        }
      })
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    disabledDate(current, old) {
      if (current) {
        // const condition = this.dataSchoolYear.tanggal_awal ? current < this.dataSchoolYear.tanggal_awal : false
        return current.isBefore(moment(this.schoolYearActive.tanggal_awal, 'YYYY-MM-DD')) || current.isAfter(moment(this.schoolYearActive.tanggal_akhir, 'YYYY-MM-DD'))
      }
      // return current && current < this.dataSchoolYear.tanggal_awal
    },
    async fetchTahunAjaranActive() {
      try {
        const data = await this.$store.dispatch('master/FETCH_TAHUN_AJARAN_ACTIVE')
        this.schoolYearActive = data || {}
      } catch (err) {
        console.log(err)
      }
    },
    checkIsCompletedEmail() {
      const totalStudents = this.menus.filter(menu => !menu.email_perwakilan_orangtua).length
      if (totalStudents) {
        this.$router.push({ name: 'Personal Data Homeroom Teacher' })
        this.$store.commit('menu/SET_STATE', {
          currentMenu: ['Personal Data Homeroom Teacher'],
        })

        this.$notification.warning({
          message: 'Warning',
          description: `There are still ${totalStudents} Student(s) who dont have their Parent Email, Please complete it first.`,
          duration: 8,
        })
      } else {
        this.handleToggleModalSendConfirmation()
      }
    },
    handleToggleModalSendConfirmation() {
      this.isVisibleModalSendConfirmation = !this.isVisibleModalSendConfirmation
      if (this.isVisibleModalSendConfirmation) {
        this.formSendConfirmation.selectedDates = this.selectedDates
      }
    },
    async handleOkModalSendConfirmation() {
      try {
        this.isConfirmLoadingModalSendConfirmation = true
        const { idKelas } = this.dataClass
        const formData = {
          ...this.formSendConfirmation,
          selectedDates: this.formSendConfirmation.selectedDates?.map(date => date.format('YYYY-MM-DD')),
        }
        if (!formData.selectedDates?.length || formData.selectedDates?.includes('' || null)) {
          return this.$notification.error({
            message: 'Vaildation Error',
            description: 'Please fill the reporting date',
          })
        }
        await this.$store.dispatch('homeroom/SEND_PERIODIC_REPORT', { idKelas, formData })
        this.handleToggleModalSendConfirmation()

        this.formSendConfirmation = {
          selectedDates: null,
          reportType: 'status',
        }
        return this.$notification.success({
          message: 'Success',
          description: 'The process of sending Periodic Reports has been successful',
        })
      } catch (error) {
        this.handleToggleModalSendConfirmation()
        this.formSendConfirmation = {
          selectedDates: null,
          reportType: 'status',
        }
        console.log(error)
        return this.$notification.error({
          message: 'Error',
          description: 'The process of sending Periodic Reports encountered problems. Please try again later',
        })
      } finally {
        this.isConfirmLoadingModalSendConfirmation = false
      }
    },
    async fetchDataListMurid() {
      try {
        this.isLoadingPage = true
        const { idKelas } = this.dataClass
        const { data } = await this.$store.dispatch('homeroom/FETCH_LIST_STUDENTS_CLASS', { idKelas })
        this.menus = data
        this.menuActive = this.menus[0]?.key
        this.fetchGraduationLetter()
      } catch (error) {
        console.log(error)
        return this.$notification.error({
          message: 'Error',
          description: 'The process of fetching list students encountered problems. Please try again later',
        })
      } finally {
        this.isLoadingPage = false
      }
    },
    async fetchGraduationLetter() {
      try {
        this.loadingListPersonalData = true
        this.loadingDetailPersonalData = true
        const { idKelas } = this.dataClass
        const res = await this.$store.dispatch('curriculum/FETCH_GRADUATION_LETTER_BY_CLASS', { idKelas })
        this.loadingListPersonalData = false
        this.loadingDetailPersonalData = false
        this.arrayGraduationLetter = res.data
        this.isMetadata = res.isMetadata
        this.menuActive = 1
        // let no = 1
        // this.menus = res.map(el => {
        //   return {
        //     key: no++,
        //     menu: `${el.nama}`,
        //     ...el,
        //   }
        // })
        return new Promise((resolve, reject) => resolve())
      } catch (err) {
        this.loadingListPersonalData = false
        this.loadingDetailPersonalData = false
        return new Promise((resolve, reject) => reject(err))
      }
    },
    handleChangeReportType(value) {
      console.log(value)
    },
    exportGraduationLetter(type = 'export') {
      if (type === 'export') this.loadingExport = true
      else if (type === ' print') this.loadingPrint = true
      // this.$refs.html2Pdf.generatePdf()
      const data = document.getElementById('reportHtml')
      this.isExported = true
      const doc = new JsPdf({
        // orientation: 'p',
        unit: 'px',
        format: 'a4',
        // putOnlyUsedFonts: true,
      })
      const { data_murid: murid, data_kop_surat: kop } = this.dataGraduationLetter
      doc.html(data, {
        callback: (doc) => {
          this.isExported = false
          if (type === 'print' || type === 'export') {
            if (type === 'export') doc.save(`SKL ${murid.nama.toUpperCase()} ${kop.nama_sekolah} ${kop.tahun_ajaran}` + '.pdf')
            else {
              // doc.autoPrint()
              // doc.output('dataurlnewwindow')
              window.open(URL.createObjectURL(doc.output('blob')))
            }
            this.loadingExport = false
            this.loadingPrint = false
          }
        },
        html2canvas: {
          letterRendering: true,
          useCORS: true,
          scale: 0.6,
        },
        margin: [10, 10, 10, 10],
      })
      // doc.text('Hello world!', 10, 10)
      // doc.save('a4.pdf')
    },
    onChangeConfirmationSelectedDate(date, dateString) {
      this.formSendConfirmation.selectedDates = date
    },
    onChangeDates(date, dateString) {
      this.selectedDates = date.length ? date : null
    },
    menuChange(payload) {
      this.menuActive = payload
    },
    async donwloadAllFile() {
      try {
        this.isExportedAll = true
        const doc = new JsPdf({
          // orientation: 'p',
          unit: 'px',
          format: 'a4',
          // putOnlyUsedFonts: true,
        })
        const data = document.getElementById('allGraduationLetterHtml')
        doc.html(data, {
          callback: (doc) => {
            this.isExportedAll = false
            const { kelas, tahunAjar } = this.dataClass
            doc.save(`SKL ${kelas} ${this.institusi.nama} TAHUN AJARAN ${tahunAjar}` + '.pdf')
            // else {
            //   // doc.autoPrint()
            //   // doc.output('dataurlnewwindow')
            //   window.open(URL.createObjectURL(doc.output('blob')))
            // }
            this.$notification.success({
              message: 'SUCCESS DOWNLOAD ALL GRADUATION LETTER',
            })
          },
          html2canvas: {
            letterRendering: true,
            useCORS: true,
            scale: 0.6,
          },
          margin: [10, 10, 10, 10],
        })
      } catch (err) {
        this.isExportedAll = false
        console.log(err)
      }
    },
  },
  computed: {
    menuName() {
      const menu = this.menus.filter(el => el.key === this.menuActive)[0]
      return menu
    },
    isDisabledSticky() {
      if (!this.isDesktop) return true

      return false
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
    institusi() {
      return this.$store.state.master.institusi
    },
    dataGraduationLetter() {
      const dataMurid = this.menus[this.menuActive - 1]
      if (dataMurid) {
        const dataGrad = this.arrayGraduationLetter.find(grad => grad.data_murid.id_murid === dataMurid.idMurid)
        return {
          ...dataGrad,
          key: this.menuActive,
        }
      }
      return {}
    },
  },
}
</script>

<style lang="scss">
.scrolling-card {
  height: 500px;
  overflow-y: auto;
}
</style>
